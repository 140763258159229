import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest és un bolet de capell delicat i poc carnós de 3 a 10 cm de diàmetre amb un mamelló central. El capell al principi és convex o acampanat per passar a aplanat, de color marró groguenc, més obscur al centre. Presenta esquames molt fines i en forma de metxes i restes de vel. Les làmines són fines i atapeïdes, lliures del peu o molt escotades i de color terrós. Després de l’esporada, les làmines tornen negres. El peu és cilíndric, de 2 a 4 cm x 8 a 12 mm, fràgil i estriat de dalt a baix. A més, presenta abundants esquames blanques que el recobreixen a la part mitja i inferior del peu. Les espores són negroses en massa, en forma d’ametla, amb berrugues i crestes, de 8-11 x 5-6,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      